import React, {createRef} from 'react';
import Layout from 'components/common/layout/layout';
import {Secction1, Secction2, Secction3, CameraBG} from 'components/home/Content/occss-home';
import LinkArrow from "components/ui/atoms/LinkArrow";
import theme from "styles/style";
import Contacto from "components/common/contacto/Contacto";
import team from "components/home/Content/assets/team.jpg";
import Hero from "../components/common/cover/hero";
import {graphql} from "gatsby";
import { SEO, StructuredData } from 'components/common/SEO';

const ComoTrabajamos = ({data}) => {
    
    const start = createRef(null);
    const block_1 = createRef(null);
    const end = createRef(null);

    const adjust = {
        start: { left: 80, color: `${theme.orange}`, size: 400, animation: false, center: false},
        block_1: { left: 10, color: `${theme.blue}`, size: 200, animation: true, center: false},
        block_2: { left: 80, color: `${theme.blue}`, size: 250, animation: true, center: false},
    };    

    return (
        <>
        <SEO
              title = "Cómo trabajamos en Obtura. Nuestro proceso"
              description= "En Obtura tenemos nuestra forma de hacer las cosas. Conoce el proceso que lleva la creación de cualquiera de nuestros proyectos audiovisuales aquí."
              robots = 'index, follow'
              canonical = 'como-trabajamos'
          />
        <Layout start={start} block_1={block_1} end={end} adjust={adjust} >
            <Hero to={"#Como-trabajamos"} src={data.sanityHeroPages.codeVideo__landscape}  vsrc={data.sanityHeroPages.codeVideo__portrait} title={"Portada"} />
            <div className="container" ref={start} ></div>
            <Secction1 className="container Home__S1 padding-xl" id={"Como-trabajamos"}>
                <div>
                    <h1 className={"HomeS1__m-title putDot--orange"}> OBTURA CREACIÓN <br/>AUDIOVISUAL</h1>
                    <div className={"HomeS1__m-text"} >
                    <p>
                       En Obtura nos encanta decir que somos diferentes al resto, aunque es evidente que el trabajo audiovisual requiere de unas pautas y unos procesos de
                       los que no nos olvidamos. Tenemos 4 etapas muy bien diferenciadas. ¿Las quieres conocer? Pues vamos con ellas:.
                    </p><p>
                        Primera fase: conocer al cliente y al proyecto. Queremos conocerte a fondo y desarrollar el trabajo previo de preproducción. Es clave para cualquier productora audiovisual saberlo todo acerca de la empresas o la persona para la que trabajamos. Así que nos tendremos que ver las caras y charlar un buen rato.

                    </p>
                    </div>
                </div>
                <div className={"HomeS1__m-picture"}>
                    <picture>
                        <img src={team} alt={"Team"} width={"611"} loading={"lazy"} />
                    </picture>
                </div>
                <div className={"a-linkContact"}>
                    <LinkArrow to={"/quienes-somos/#Quienes-somos"} text={"CONÓCENOS"} color={theme.orange} />
                </div>
            </Secction1>
            <div className="container" ref={block_1} ></div>
            <Secction2 className="container Home__S2 padding-xl">
            <div className={"HomeS2__m-title"}>
                <h2 className={"h1 "}> IMAGINAMOS<span className="orange" >.</span> CREAMOS
                <span className="blue" >.</span><br/> PRODUCIMOS<span className="green" >.</span> </h2>
            </div>
            <div className={"HomeS2__m-text"}>
                <p >
                Cuéntanos con todo lujo de detalles tus necesidades y tus objetivos. Una vez los hayamos interiorizado ya podremos poner en marcha toda la maquinaría de creación (guiones, brainstorming, etc.). No te preocupes porque antes de encender las cámaras te enviaremos la correspondiente propuesta formal.
            </p><p>
                Segunda fase. Si nos seguimos viendo a estas alturas, es muy buena noticia. ¡Significa que en Obtura vamos a trabajar para cubrir tus necesidades audiovisuales! Ya habremos dejado el papeleo listo y llegará nuestro momento favorito: rodar. La culminación de la segunda fase se
                produce cuando las ideas del primer punto han evolucionado hasta convertirse en imágenes o vídeo. O ambas cosas.
            </p>
            </div>
        </Secction2>
        <CameraBG className={"padding-xl"}>
            <Secction3 className="container Home__S3 padding-xl">
                <div>
                <h2 className={"h1 HomeS3__m-title putDot--blue"}> NUESTROS VALORES </h2>
                </div>
            <div className={"HomeS3__m-text"}>
                <p>
                    Tercera fase: la postproducción. Tra las sesiones de fotografía o de rodaje nos toca ponernos frente al ordenador y sacar a relucir toda la magia de la edición. Serán muchas horas de estudio,
                    pero merecerán la pena. Iremos dando forma a los resultados finales e irás viendo los avances. No te preocupes porque sabemos que no se acierta a la primera.  Veremos contigo los cambios que necesites y los aplicaremos.
                </p><p>
                   Fase final. Llega el momento crucial: la entrega del proyecto. Revisar y entregar el producto final y el material correspondiente es un subidón. ¿Quieres que vivamos juntos todos estos momentos? Contacta con nosotros para ver qué servicios audiovisuales puedes necesitar para relanzar tu
                   marca.
                </p>
            </div>
            </Secction3>
        </CameraBG>
        <div className={"container padding-xl"} id={"contactar"} ref={end}>
                <Contacto />
            </div>
        </Layout>
        </>
    )
}
export const query = graphql`
  query ComoTrabajamosPageQuery {
    sanityHeroPages(pages: {eq: "como-trabajamos"}) {
        codeVideo__landscape
        codeVideo__portrait
    }
  }
`

export default ComoTrabajamos
