import styled from 'styled-components';
import theme from 'styles/style.js'

export const RRSS = styled.p`
  border-bottom: 1px solid #5E6180;
  width: 100%;
  text-align: right;
  padding-bottom: 1em;
  @media (max-width: 768px) {
    border-top: 1px solid #5E6180;
    padding-bottom: 0;
    padding-top: 0.5em;
    border-bottom: none;
  }
`

export const SectionContact = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  a {
    transition: 0.4s;
    &:hover {
      transition: 0.4s;
    }
  }
  
  .contact__titles{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 1em;
    margin-right: 3em;
    width: 90%;
    max-width: 700px;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      padding: 0;
      width: 100%;
    }
  }
  
  .contact__details{
    width: 300px;
    margin-top: 2.5em;
    @media (max-width: 768px) {
      align-self: flex-start;
    }
  }
  
  .contact__link {
    fill: ${theme.orange};
    &:hover {
      fill: ${theme.blue};
    }
  }
  
  .h1{
    width: 100%;
    text-align: right;
    margin:0;
    @media (max-width: 768px) {
      text-align: left;
      font-size:  ${theme.fsTitleS};
    }
  }
  
  
`