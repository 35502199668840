import React from 'react';
import {RRSS, SectionContact} from './style-contacto';
import theme from 'styles/style';
import Link from "gatsby-link";
import {graphql, useStaticQuery} from "gatsby";

export const Contacto = () => {
    const data = useStaticQuery(graphql`
        query contact {
            site {
              siteMetadata {
                title
                foundingDate
                contact{
                    email
                    phone
                    phoneNoSpacing
                }
                socialLinks{
                    linkedin
                    instagram
                    youtube
                }
                address {
                streetAddress
                zipCode
                city
                }
              }
            }
          }
    `);
    return (
        <SectionContact>
            <div className={"contact__titles"}>
                <RRSS>
                    <a href={data.site.siteMetadata.socialLinks.youtube} target={"_blank"} rel={"noreferrer noopener nofollow"} > YOUTUBE </a> -
                    <a href={data.site.siteMetadata.socialLinks.instagram} target={"_blank"} rel={"noreferrer noopener nofollow"} >  INSTAGRAM </a> -
                    <a href={data.site.siteMetadata.socialLinks.linkedin} target={"_blank"} rel={"noreferrer noopener nofollow"} >  LINKEDIN </a>
                </RRSS>
                <Link to={"/contacto/"} >
                    <svg className={"contact__link"} xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                        <g id="Grupo_46" data-name="Grupo 46" transform="translate(-242 -2441)">
                            <g id="Elipse_5" data-name="Elipse 5" transform="translate(242 2441)" stroke="none" strokeWidth="1">
                                <circle cx="21" cy="21" r="21" stroke="none"/>
                                <circle cx="21" cy="21" r="20.5" fill="none"/>
                            </g>

                            <g id="Grupo_16" data-name="Grupo 16" transform="translate(1337.255 3989.052) rotate(-130)">
                                <g id="Grupo_4" data-name="Grupo 4" transform="translate(1853 158.696)">
                                    <line id="Línea_7" data-name="Línea 7" x2="6.696" y2="6.696" fill="none" stroke={theme.textColor} strokeLinecap="round" strokeWidth="1"/>
                                    <line id="Línea_9" data-name="Línea 9" x1="6.696" y2="6.696" transform="translate(6.696)" fill="none" stroke={theme.textColor} strokeLinecap="round" strokeWidth="1"/>
                                </g>
                                <g id="Grupo_5" data-name="Grupo 5" transform="translate(1853 152)">
                                    <line id="Línea_7-2" data-name="Línea 7" x2="6.696" y2="6.696" fill="none" stroke={theme.textColor} strokeLinecap="round" strokeWidth="1"/>
                                    <line id="Línea_9-2" data-name="Línea 9" x1="6.696" y2="6.696" transform="translate(6.696)" fill="none" stroke={theme.textColor} strokeLinecap="round" strokeWidth="1"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <h2 className={"h1 putDot--white"}> CONTÁCTANOS</h2>
                </Link>
            </div>
            <div className={"contact__details"}>
                <address>
                    <a href={`mailto:${data.site.siteMetadata.contact.email}`} className={"a-contact-link"} target={"_blank"} rel={"noreferrer noopener"}>{data.site.siteMetadata.contact.email}</a><br/>
                    <a href={`tel:${data.site.siteMetadata.contact.phoneNoSpacing}`} className={"a-contact-link"} target={"_blank"} rel={"noreferrer noopener"}>{data.site.siteMetadata.contact.phone}</a><br/>
                    {data.site.siteMetadata.address.streetAddress}, {data.site.siteMetadata.address.zipCode}.<br/>
                    {data.site.siteMetadata.address.city}.
                </address>
            </div>
        </SectionContact>
    );
};

export default Contacto;